import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

import './Footer.css'

class Footer extends Component {
    render(){
        const year = new Date().getFullYear();        
        const copyrights = `© ${year} Solutions Lacerta`;
        return (
            <div id="apropos">
                <div id="footer_column_div">
                    <div className="footer_column">
                        <h1 className="div_heading footer">Notre mission</h1>
                        <p className="div_subheading_footer footer_sub">Le Québec est riche en évènements de qualité, et nous travaillons main dans la main avec nos partenaires depuis plus de 10 ans pour que l'expérience mobile des festivaliers soit aussi agréable qu'efficace.</p>
                    </div>

                    <div className="footer_column">
                        <h1 className="div_heading footer">Sur le web</h1>
                        <ul>
                            <a href="https://www.facebook.com/profile.php?id=61550501874795" className='footer_social_container' target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className='footer_social' icon={faFacebookF} />
                            </a>
                            <a href="https://www.linkedin.com/company/solutionslacerta" className='footer_social_container' target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon className='footer_social' icon={faLinkedinIn} />
                            </a>
                        </ul>
                        
                    </div>
                    <div className="footer_column">
                        <h1 className="div_heading footer">Contact</h1>
                        <div className="footer_button">
                            <a href="mailto:info@lacerta.ca">Nous joindre</a>
                        </div>
                    </div>
                </div>
                <p className="footer_copyrights">{copyrights.toString()}</p>
            </div>
        )
    }
}

export default Footer;