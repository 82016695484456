import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import Pricing from './pages/Pricing/Pricing';
import PageNotFound from './pages/PageNotFound';

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/prix" element={<Pricing />} />
      <Route path="*" element={<PageNotFound />} />
    </Routes> 
  );
};

export default App;
