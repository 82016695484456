import React, { Component } from 'react';
import './Features.css'

class Features extends Component {
    render() {
        return (
            <div id="notreoffre">
                <h1 className="div_heading">Notre offre</h1>
                <div id="features_list_div">

                    {this.props.features.map((item, i) => {
                        return (
                            <div key={i} className="features_item_div">
                                <div className="features_image_div">
                                    <img src={item.image} alt={item.heading} />
                                </div>
                                <div className="features_text_div">
                                    <h1>{item.heading}</h1>
                                    <p className="div_subheading_features">{item.paragraph}</p>
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        )
    }
}

export default Features;