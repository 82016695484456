import React, { Component } from 'react';
import "./Portfolio.css"
import { ParallaxProvider, useParallax } from 'react-scroll-parallax';

const BannerContent = (props) => {

    props = props.props
    const { ref: parallaxRef } = useParallax({
        speed: -5
    })
    var phoneIs = {
        Android: function() {
            return navigator.userAgent.match(/Android/i);
        },
        iOS: function() {
            return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
    };

    return (
        <div ref={parallaxRef} id="realisations_list_div">
            {props.portfolio.map((item, i) => {
                const href = phoneIs.iOS() ? item.ioshref : item.androidhref;
                return (
                    <a className="" href={href ? href : "/"} target="_blank" rel="noopener noreferrer" key={i}>
                        <div key={i} className="realisations_item_div">
                            <img src={item.image} alt={item.heading} />
                            <h1 className="light">{item.heading}</h1>
                        </div>
                    </a>
                )
            })}
        </div>
    )
}

class Portfolio extends Component {

    render() {
        return (
            <div id="portfolio">
                <h1 className="div_heading light">Nos réalisations</h1>
                <p className="portfolio_subheading light">De l'idéation jusqu'à mise en ligne, nous avons eu la chance de évènements avec ces festivals pour bonifier l'expérience mobile de leurs festivaliers.</p>
                <ParallaxProvider>
                    <BannerContent props={this.props} />
                </ParallaxProvider>
            </div>
        )
    }
}

export default Portfolio;