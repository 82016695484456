import React, { Component } from 'react';
import { Helmet } from 'react-helmet';

// THIS COMPONENT IS KINDA USELESS BECAUSE HELMET IS LOADED ONLY ON RENDERING
// AND BOTS DOES NOT WAIT FOR RENDERING WHEN CRAWLING A WEBSITE
class SEO extends Component {
    render() {
        return (
            <Helmet>

                {/* GENERIC META */}
                <meta charset="UTF-8" />
                <meta name="language" content="fr" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <meta name="robots" content="index, follow" />
    
                {/* LEGACY SEO META */}
                <title>{this.props.meta.title}</title>
                <meta name="title" content={this.props.meta.title} />
                <meta name="description" content={this.props.meta.description} />
                <meta name="keywords" content={this.props.meta.keywords} />
                <link rel="canonical" href={this.props.meta.canonical} />
    
                {/* OPEN GRAPH */}
                <meta property="og:title" content={this.props.meta.title} />
                <meta property="og:description" content={this.props.meta.description} />
                <meta property="og:url" content={this.props.meta.canonical} />
                <meta property="og:image" content={this.props.meta.image} />
    
                {/* STRUCTURED DATA */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Corporation",
                        "name": this.props.meta.name, // Utilisation de la variable this.props.meta.name
                        "alternateName": "Lacerta",
                        "url": this.props.meta.canonical,
                        "logo": "https://lacerta.ca/assets/img/og.png",
                        "sameAs": [
                            "https://www.facebook.com/profile.php?id=61550501874795",
                            "https://www.linkedin.com/company/solutionslacerta"
                        ]
                    })}
                </script>

            </Helmet>
        )
    }
}

export default SEO;