import React, { Component } from 'react';
import './Home.css';

import Nav from '../../components/Nav'
import Banner from '../../components/Banner';
import Container from '../../components/Container';
import Features from '../../components/Features';
import Portfolio from '../../components/Portfolio';
import SEO from '../../components/SEO';
import Footer from '../../components/Footer';
import content from '../../data/content.json';

class Home extends Component {
  render(){
    return (
      <div>
        <SEO meta={content.meta} />
        <Container>
          <Banner navItems={content.topMenuItems} />
          <Nav navItems={content.topMenuItems} />
          <Features features={content.features} />
          <Portfolio portfolio={content.portfolio} />
          <Footer />
        </Container>
      </div>
    );
  }
}

export default Home;