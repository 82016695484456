import React from 'react';
import './Banner.css';
import { ParallaxProvider, useParallax } from 'react-scroll-parallax';

const BannerContent = () => {
    const { ref: parallaxRef } = useParallax({
        speed: -10
    })
    return (
        <div ref={parallaxRef} id="banner_text_div">
            <h1>UNE EXPÉRIENCE MOBILE QUI PROPULSE LES ÉVÈNEMENTS D'ICI</h1>
        </div>
    )
}

const Banner = () => {
    return (
        <ParallaxProvider>
            <div id="banner_div">
                <BannerContent />
            </div>
        </ParallaxProvider>
    );
}

export default Banner;