import React, { Component } from 'react';
import { slide as Menu } from 'react-burger-menu'
import './Nav.css';

class Nav extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSticky: true,
            isMenuOpen: false
        };
        this.handleScroll = this.handleScroll.bind(this);
    }

    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        if (window.scrollY > 0) {
        this.setState({ isSticky: true });
        } else {
        this.setState({ isSticky: false });
        }
    }
    
    toggleMenu = () => {
        this.setState(prevState => ({
            isMenuOpen: !prevState.isMenuOpen
        }));
    }

    render() {
        const isMobile = window.innerWidth < 991;
        if(isMobile) {
            return (
                <div>
                    <div id="nav-container" className={"sticky " + (this.state.isSticky ? "nav-container-background" : "")}>
                        <div id="nav-logo-div">
                            <a href="/">
                                <img id="nav-logo" src="/assets/img/logo.png" alt="Lacerta" />
                            </a>
                        </div>
                        <Menu width={ '70%' } right noOverlay>
                            <div id="nav-item-list">
                                {this.props.navItems.map((item, i) => {
                                    return (
                                        <div id="nav-item" key={i}>
                                            <a className="bm-item" key={i} href={item.href}>{item.page}</a>
                                        </div>
                                    )
                                })}
                                {/* <a className='bm-item' id="nav-item-button" href="/">PORTAIL CLIENT</a> */}
                            </div>
                        </Menu>
                    </div>
                </div>
            );
        } else {
            return (
                <div id="nav_bar" className={"sticky " + (this.state.isSticky ? "nav-container-background" : "")}>
                    <div id="nav-logo-div">
                        <a href="/">
                            <img id="nav-logo" src="/assets/img/logo.png" alt="Lacerta" />
                        </a>
                    </div>
                    <div id="nav_list_items_div">
                        <ul id="nav_list">
                            {this.props.navItems.map((item, i) => {
                                return (
                                    <li key={i} className="nav_list_item">
                                        <a href={item.href}>{item.page}</a>
                                    </li>
                                )
                            })}
                            {/* <li className="nav_list_item nav_list_button">
                                <a href="/">PORTAIL CLIENT</a>
                            </li> */}
                        </ul>
                    </div>
                </div>
            );
        }
    }
}

export default Nav;
