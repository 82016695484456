import React, { useState } from 'react';
import PricingCard from '../../components/PricingCard/PricingCard';
import Container from '../../components/Container';
import Nav from '../../components/Nav';
import Footer from '../../components/Footer';
import pricing from '../../data/pricing.json';
import content from '../../data/content.json';
import './Pricing.css';

const Pricing = () => {
  const [cards, setCards] = useState(pricing);

  const onCardClick = (id) => {
    setCards(prevCards => prevCards.map(item => {
      if (item.id === id && id !== 1) {
        return { ...item, isSelected: !item.isSelected };
      }
      return item;
    }));
  };

  const total = cards.reduce((total, card) => {
    return card.isSelected ? total + card.price : total;
  }, 0);

  return (
    <div>
      <Container>
        <Nav navItems={content.topMenuItems} />
        <div id="pricing">
          <div id="pricing_title">
            <h1>Nos forfaits</h1>
          </div>
          <div id="pricing_subtitle">
            <h3>Choississez les options qui vous intéressent pour obtenir une estimation.</h3>
          </div>

          <div id="pricingcard_container_empty">            
            <PricingCard
              card={cards[0]}
              onClick={() => onCardClick(0)}
              isWideCard={true} />
          </div>

          <div id="pricingcard_container">
            {cards.map((item, i) => (
              i !== 0 && (
                <PricingCard
                  key={i}
                  card={item}
                  onClick={() => onCardClick(item.id)} 
                  isWideCard={false}/>
              )
            ))}
          </div>

          <h5>Vous avez des besoins particuliers pour votre évènement? Contactez-nous!</h5>

          <div id="pricing_cost">
            <h2>Coût pour la première année : <b>{total}$</b></h2>
            <h3>Coût pour les années subséquentes : <b>{total/2}$/an</b></h3>
          </div>

        </div>
        <Footer />
      </Container>
    </div>
  );
};

export default Pricing;
