import React, { Component } from 'react';
import "./PricingCard.css"

class PricingCard extends Component {

    handleClick = () => {
        this.props.onClick();
    }

    render() {
        const baseClass = this.props.isWideCard ? 'widepricingcard' : '';
        const selectedClass = this.props.card.isSelected ? ' pricingcard_selected' : '';
        const cardClass = `pricingcard ${baseClass} ${selectedClass}`;
        return (
            <div className={cardClass} onClick={this.handleClick}>
                <h1>{this.props.card.name}</h1>
                <h2>{this.props.card.price}$</h2>
                {this.props.card.description.map((description, i) => {
                    return <p key={i}>{description}</p>
                })}
                <h4>{this.props.card.isSelected ? "Sélectionné" : "Sélectionner"}</h4>
            </div>
        )
    }
}

export default PricingCard;
